<template>
  <div
    v-editable="blok"
    :data-insights-index="indexName"
    class="ProductList relative"
  >
    <div
      :class="[desktopWidth('full'), {
        'mb-32': blok.body.length
      }]"
      class="text-center mb-16 relative"
    >
      <!-- * * * * storyblok error -->
      <div
        v-if="showStoryblokErrors.length"
        class="bg-lighter border border-darkest absolute top-0 right-0 z-globalError flex p-8 text-left"
      >
        <div class="mr-16">
          <img
            src="/admin/circle-exclamation.svg"
            class="w-32 mr-8"
            alt="warning"
          >
        </div>
        <ul class="list-disc">
          <li v-for="(error, index) in showStoryblokErrors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>

      <!-- * * * * breadbrumbs -->
      <AutoBreadcrumbs
        v-if="blok.showBreadcrumbs"
        :exclude-self="autoPagePath.length === 0"
        :exclude-top-level="false"
      />

      <!-- * * * * img -->
      <div v-if="blok.logo && blok.logo.filename && blok.logoAboveHeadline">
        <img
          :src="blok.logo.filename"
          class="inline-block w-full h-full max-h-96 max-w-208 mb-12 desk:mb-24"
          :alt="blok.logo.alt"
        >
      </div>

      <!-- * * * * headline -->
      <div class="desktopMaxW-xs">
        <component
          :is="blok.headlineLevel"
          v-if="blok.headline"
          class="type-headline-lg desk:type-headline-xl"
        >
          {{ autoGeneratedHeadline ? autoGeneratedHeadline : headline }}
        </component>
        <div
          v-if="blok.text"
          class="mt-8 empty:hidden mobOnly:px-12"
          v-html="renderRichText(blok.text)"
        />
      </div>
      <!-- * * * * img -->
      <div v-if="blok.logo && blok.logo.filename && !blok.logoAboveHeadline">
        <img
          :src="blok.logo.filename"
          class="inline-block w-full h-full max-h-96 max-w-208"
          :alt="blok.logo.alt"
        >
      </div>

      <!-- * * * * add to favorites -->
      <div v-if="blok.favoriteBrandButton && isBrandPage && brand?.brand?.id && userStore.loggedIn" class="flex justify-center my-8">
        <AddRemoveFavoriteBrand
          :brand-id="brand.brand.id"
          design="withText"
        />
      </div>

      <!-- * * * * scroll to products -->
      <button
        v-if="blok.scrollToProductsButton"
        class="btn mx-auto mt-16"
        @click="jumpToProducts"
      >
        {{ $t('filter.jumpToProducts') }}
        <img
          src="/icons/arrow-down-long-inv.svg"
          class="w-16 h-16 ml-8"
          alt=""
        >
      </button>
    </div>

    <!-- * * * * sidescroll sub pages / fastLinksAvailable -->
    <div
      v-if="fastLinksAvailable"
      class="w-full mb-16 mobOnly:pb-16 mobOnly:border-b border-light desk:layout-container"
    >
      <component :is="uiStore.isMobile ? SideScroll:'div'">
        <div class="flex mobOnly:pl-12 desk:flex-wrap desk:justify-center">
          <template
            v-for="(nav, index) in autoGenerateNav"
            :key="nav.code"
          >
            <nuxt-link
              v-if="nav.path"
              :to="nav.path"
              class="btn btn--secondary btn--md mr-8 mb-8 last:mr-0"
            >
              {{ nav.name }}
            </nuxt-link>
            <button
              v-else
              class="btn btn--secondary btn--md mr-8 mb-8 last:mr-0"
              :class="{
                selected: nav.selected
              }"
              @click="sendToFilterPanel(index)"
            >
              {{ nav.name }}
            </button>
          </template>

          <div class="sideScrollRightPad desk:hidden" />
        </div>
      </component>
    </div>

    <!-- * * * * Middle content -->
    <SpaceHandler
      v-for="(childBlok, index) in blok.body"
      :key="childBlok._uid"
      :blok="childBlok"
    >
      <ProductList
        v-if="childBlok.component === 'ProductList'"
        :blok="childBlok"
        :placement="index + 1"
      />
      <StoryblokComponent
        v-else
        :blok="childBlok"
        :placement="index + 1"
      />
    </SpaceHandler>

    <!-- * * * * Admin selected tabs -->
    <component
      :is="uiStore.isMobile ? SideScroll:'div'"
      v-if="showTabs && !isTabsInline"
      :class="[desktopWidth('full')]"
      class="mb-12"
    >
      <div class="flex desk:flex-wrap border-b border-light">
        <ProductListTabs
          :selected-admin-filter="selectedAdminFilter"
          design="tabs"
          :admin-filters="blok.adminFilters"
          @select="toggleAdminFilter"
        />
        <div class="sideScrollRightPad desk:hidden" />
      </div>
    </component>

    <div
      v-if="initalAlgoliaResponse"
      :class="[desktopWidth('full'), {
        'desk:border-t border-light': blok.enableFilter && !showTabs
      }]"
    >
      <div ref="topOfProductList" />
      <div v-if="totalProducts <= 0" class="text-center pt-24 max-w-460 mx-auto">
        <div class="inline-block type-headline-lg mb-12">
          {{ $t('productList.empty.headline') }}
        </div>
        <p>{{ $t('productList.empty.text') }}</p>
      </div>
      <div class="desk:flex items-start">
        <div
          v-if="blok.enableFilter && !showTabs"
          class="desk:hidden px-12"
        >
          <button class="btn w-full" @click="showFilterMobile = true">
            {{ $t('filter.button') }}
            <img
              src="/icons/filter-inv.svg"
              class="w-16 h-16 ml-12"
              alt="filter"
            >
          </button>
        </div>

        <div
          v-if="showFilter"
          class="basis-240 shrink-0 desk:mr-24"
          :class="{
            'basis-[318px]': blok.desktopDesign === 'large'
          }"
        >
          <client-only>
            <transition :name="uiStore.isMobile ? 'fromRight':''">
              <FilterPanel
                v-show="!uiStore.isMobile || showFilterMobile"
                ref="filterPanel"
                :rendering-content="initalAlgoliaResponse?.renderingContent as AlgoliaRenderingContent"
                :facets-current="facetsCurrent"
                :facets-initial="facetsInitial"
                :total-products="totalProducts"
                :used-admin-filter="usedAdminFilter"
                :start-user-filter="userFilters"
                @set-filter="setFilter"
                @close-filter="showFilterMobile = false"
              />
            </transition>
          </client-only>
        </div>

        <div
          class="basis-full transition-opacity"
          :class="{
            'opacity-0 duration-1000': loading,
            'opacity-100': !loading
          }"
        >
          <div
            v-if="totalProducts > 0 && blok.enableFilter && !showTabs"
            class="flex justify-between items-center pt-4 desk:pt-[15px] desk:pb-12 mobOnly:px-12
          type-headline-xs desk:type-headline-sm"
          >
            <div class="whitespace-nowrap mr-12">
              {{ totalProducts > 1 ? $t('filter.countResults.plur', {num: totalProducts}) : $t('filter.countResults.sing') }}
            </div>
            <div class="flex flex-row items-center gap-8 desk:gap-16">
              <div v-if="!hideSort" class="flex items-center">
                <div class="type-headline-sm mobOnly:type-headline-xs relative mr-8">
                  {{ $t('filter.sort.prefix') }}
                </div>
                <NativeSelect
                  v-model="selectedOption"
                  class="type-headline-xs"
                  :options="sortOptions"
                  select-class="mobOnly:type-headline-xs"
                />
              </div>
              <div
                class="flex flex-row gap-8"
                :class="{'hidden': isPointShop}"
              >
                <button
                  class="view w-24 h-24 opacity-50 hover:opacity-100 transition-all"
                  :class="{'active--grid': viewMode === 'grid'}"
                  @click="setView('grid')"
                >
                  <img
                    src="/icons/grid.svg"
                    class="w-24 h-24"
                    alt="grid"
                  >
                </button>
                <button
                  class="view w-24 h-24 opacity-50 hover:opacity-100 transition-all"
                  :class="{'active--list': viewMode === 'list'}"
                  @click="setView('list')"
                >
                  <img
                    src="/icons/list.svg"
                    class="w-24 h-24"
                    alt="list"
                  >
                </button>
              </div>
            </div>
          </div>

          <!-- * * * * * * * * * * * * * slider -->
          <div
            v-if="showSlider"
            :class="[desktopWidth('full')]"
          >
            <client-only>
              <div
                v-if="showSliderButtons"
                class="hidden desk:flex justify-between relative w-full"
              >
                <div
                  class="swiper-button-prev absolute !-left-16"
                  :class="[`a${blok._uid}`]"
                  :style="desktopSliderBtnStyle"
                />
                <div
                  class="swiper-button-next absolute !-right-16"
                  :class="[`a${blok._uid}`]"
                  :style="desktopSliderBtnStyle"
                />
              </div>
              <Swiper
                :key="`swiperForTab${swiperIndex}`"
                :space-between="uiStore.isMobile ? 4 : 12"
                :slides-per-view="slidesPerView"
                :slides-offset-before="0"
                :slides-offset-after="uiStore.isMobile ? 24 : 0"
                :modules="[SwiperNavigation, SwiperPagination]"

                :navigation="{
                  nextEl: `.swiper-button-next.a${blok._uid}`,
                  prevEl: `.swiper-button-prev.a${blok._uid}`,
                }"
                :pagination="{
                  dynamicBullets: true,
                  clickable: true,
                }"
                @resize="swiper => { updateSwiperH(swiper.height) }"
              >
                <template
                  v-for="(product, slideIndex) in products"
                  :key="product.partNo"
                >
                  <SwiperSlide
                    v-if="slideIndex === 0 && isTabsInline"
                    :style="swiperHstyle"
                  >
                    <ProductListTabs
                      :selected-admin-filter="selectedAdminFilter"
                      :admin-filters="blok.adminFilters"
                      design="inline"
                      :tabs-headline="blok.tabsHeadline"
                      @select="toggleAdminFilter"
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <ProductCard
                      :key="product.partNo + slideIndex"
                      v-track="{product, index: slideIndex, pageName: globalContent.currentStory.name, pageId: globalContent.currentStory.id}"
                      class="algoliaClickTrigger"
                      :product="product"
                      :enable-quick-buy="blok.enableQuickBuy"
                      :point-shop="isPointShop"
                      :use-variant-image="isPointShop"
                      :placement="1"
                      :design="productCardDesign"
                      :data-insights-object-id="product.objectID"
                      :data-insights-position="slideIndex + 1 + page * hitsPerPage"
                      :data-insights-query-id="queryID"
                    />
                  </SwiperSlide>

                  <SwiperSlide
                    v-if="isTabWithImage && slideIndex === 0"
                    :style="swiperHstyle"
                  >
                    <ProductListTabImage
                      v-if="isTabWithImage"
                      :filter="props.blok.adminFilters[selectedAdminFilter]"
                      :mobile-design="blok.mobileDesign"
                      :desktop-design="blok.desktopDesign"
                    />
                  </SwiperSlide>
                </template>
              </Swiper>
            </client-only>
          </div>

          <!-- * * * * * * * * * * * * * display grid -->
          <div
            v-else
            :class="[mobileDesignOut, desktopDesign]"
          >
            <div v-if="viewMode === 'list'" class="px-12 desk:px-0">
              <div class="w-full h-1 bg-light" />
            </div>
            <div
              v-if="isTabsInline"
              class="tabImageHeight order-1"
            >
              <ProductListTabs
                :selected-admin-filter="selectedAdminFilter"
                :admin-filters="blok.adminFilters"
                design="inline"
                :tabs-headline="blok.tabsHeadline"
                @select="toggleAdminFilter"
              />
            </div>
            <!-- tab image -->
            <ProductListTabImage
              v-if="isTabWithImage"
              :filter="props.blok.adminFilters[selectedAdminFilter]"
              :mobile-design="blok.mobileDesign"
              :desktop-design="blok.desktopDesign"
            />
            <div
              v-for="(product, index) in products"
              :key="product.partNo"
              :style="productCardOrder(index)"
            >
              <ProductCard
                v-track="{product, index, pageName: globalContent.currentStory.name, pageId: globalContent.currentStory.id}"
                class="algoliaClickTrigger"
                :product="product"
                :enable-quick-buy="blok.enableQuickBuy"
                :point-shop="isPointShop"
                :use-variant-image="isPointShop"
                :placement="placement"
                :design="productCardDesign"
                :data-insights-object-id="product.objectID"
                :data-insights-position="index + 1 + page * hitsPerPage"
                :data-insights-query-id="queryID"
              />
            </div>
          </div>

          <div
            v-if="blok.showLoadMore && !showTabs && !isManuallySelectedProducts && totalProducts > 0"
            class="flex justify-center"
            :class="{
              'mt-32': viewMode === 'list'
            }"
          >
            <LoadProgress
              :current="Math.min((page + 1)*hitsPerPage, totalProducts)"
              :total="totalProducts"
              text="filter.viewed"
              :loading="loading"
              @load-more="loadResults(true, false)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Hello who ever is going to change in this file, propbably future me
 * it is very feature creeped, and also based on the older VV component
 *
 * One note; FilterPanel is the primary data source for the filter
 * It seemed like a good idea at the time, but if we ever
 * do a big refactor of this, I would keep all data in this component,
 * and make FilterPanel and FilterPanelButtons stupid components, and
 * use defineModel
 */
import {
  FilterTypeAlgolia,
  LinkAndTitle, SingleSelectBrandAlgolia,
  StoryBlokAsset,
  StoryBlokPropBase
} from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import ProductCard from '~/components/product/ProductCard.vue';
import {
  AlgoliaFacets,
  AlgoliaRenderingContent, AlgoliaSearchPayloadRequestOptions, AlgoliaSearchResult,
  ProductRaw,
  UserFilters
} from '~/constants/types/algolia';
import FilterPanel from '~/components/product/FilterPanel.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import LoadProgress from '~/components/generic/LoadProgress.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { SbBlokData } from '@storyblok/vue';
import SpaceHandler from '~/components/body/SpaceHandler.vue';
import SideScroll from '~/components/globals/SideScroll.vue';
import AutoBreadcrumbs from '~/components/body/AutoBreadcrumbs.vue';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import AddRemoveFavoriteBrand from '~/components/product/AddRemoveFavoriteBrand.vue';
import { ISbRichtext } from 'storyblok-js-client';
import { useCartStore } from '~/store/cart';
import ProductListTabs from '~/components/product/ProductListTabs.vue';
import ProductListTabImage from '~/components/product/ProductListTabImage.vue';
import { pushToDataLayer, useProductImpressionTracking as vTrack } from '~/composeables/useTracking';
import { getLocalCurrency } from '~/composeables/useLocalizedCurrency';
import Product, { ProductModel } from '~/models/product';
import NativeSelect from '~/components/body/NativeSelect.vue';

const { $t, $sbLinkFix, $sbLinkCheck } = useNuxtApp();
const uiStore = useUiStore();
const userStore = useUserStore();
const globalContent = useGlobalContentStore();
const route = useRoute();
const { getFromAlgolia, getProductsByUrl, convertRawToProducts, getProductsByPartNo } = useAlgoliaFetch();
const NuxtLink = resolveComponent('NuxtLink');

const indexName = ref('');
const isPointShop = computed(()=> {
  return route.name?.toString()?.includes('checkout-pointshop-basketId') || route.path.endsWith('/sitesettings/checkout-pointshop');
});
indexName.value = globalContent.getAlgoliaIndex;

// _points

const emit = defineEmits<{
  (e: 'totalProductsInital', input: number): void;
  (e: 'totalProducts', input: number): void;
}>();

/**
 * @todo - see if we can limit what facets we get to minimize response
 */
//const shownFacets = ['brandFilters','categoryFilters', 'parametricFilters.volume', 'parametricFilters.color', 'flagFilters'];
const shownFacets = ['*']; // selects all facets

const initialLoaded = ref(false);
const userFilters = ref<UserFilters>({});
const products = ref<ProductModel[]>([]);
const facetsCurrent = ref<AlgoliaFacets>({}); // current facets
const facetsInitial = ref<AlgoliaFacets>({}); // from first load- before user filtering
const showFilterMobile = ref(false);
const page = ref(0);
const totalProducts = ref(-1);
const loading = ref(false);
const topOfProductList = ref<HTMLElement | null>(null);
const selectedAdminFilter = ref(0);
const cartStore = useCartStore();
const viewModeCookie = useCookie<'grid' | 'list'>('userListViewmode', { maxAge: 3600 * 24 * 182, path: '/', default: () => 'grid' });

type Props = StoryBlokPropBase & {
  headline: string,
  headlineLevel: string,
  text?: ISbRichtext,
  limit: string, // sb don't have numbers, apparently
  limitDesktop: string,
  adminFilters: FilterTypeAlgolia[],
  tabsInline: boolean, // show desktop tabs as first productcard instead of on top
  tabsHeadline: string,
  showBreadcrumbs: boolean,
  enableFilter: boolean,
  logo?: StoryBlokAsset,
  logoAboveHeadline: boolean,
  showLoadMore: boolean,
  enableQuickBuy: boolean,
  body: SbBlokData[],
  scrollToProductsButton: boolean,
  favoriteBrandButton: boolean,
  subPages: 'off' | 'brand-subcategories' | 'subpages' | 'subpages-folder' | 'subpages-pages',
  subPagesManual: LinkAndTitle[],

  desktopDesign: 'slider' | 'default' | 'large' | 'featured' | 'slider-large' | 'slider-featured',
  mobileDesign: 'grid' | 'slider' | 'featured',
}

const props = defineProps<{
  blok: Props,
  placement: number,
  isBrandPage?: boolean,
  brand?: SingleSelectBrandAlgolia,
  searchQuery?: string,
  indexSuffix?: string,
  hideSort?: boolean,
}>();

if (props.indexSuffix) {
  indexName.value = globalContent.getAlgoliaIndex + props.indexSuffix;
}

const { desktopWidth } = useDesktopWidth(props);

const hitsPerPage = computed(()=> {
  return uiStore.isMobile ? parseInt(props.blok.limit) :  parseInt(props.blok.limitDesktop);
});

const showTabs = computed<boolean>(()=> {
  return props.blok.adminFilters?.length > 1;
});

/**
 * If filter is selected and can be used
 */
const showFilter = computed(()=> {
  if (!props.blok.enableFilter) {
    return false;
  }
  if (showTabs.value) {
    return false;
  }
  if (props.blok.adminFilters[0]?.manual?.length) {
    return false;
  }
  return true;
});

/**
 * View mode = "list" can not be displayed everywhere, only when combined with filter
 */
const viewMode = computed<'grid' | 'list'>(()=> {
  if (!showFilter.value || !props.blok.enableFilter || isPointShop.value ) {
    return 'grid';
  }
  return viewModeCookie.value;
});

/**
 * Reads path for auto generated pages
 */
const autoPagePath = computed(()=> {
  let pathArr = route.path.split('/');
  const filters = pathArr.findIndex((f)=> f === 'filterpage');
  if (filters > -1) {
    return pathArr.slice(filters + 1).map(m => decodeURI(m));
  }
  return  [];
});
const autoPageFilters = computed(()=> {
  let pathArr = route.path.split('/') as string[];
  const filters = pathArr.findIndex((f)=> f === 'filterpage');
  if (filters > -1) {
    let output = {} as { [key: string]: string[]; };
    pathArr = pathArr.slice(filters + 1);
    pathArr.forEach((e) => {
      const thisE = e.split(':');
      if (!output[thisE[0]]) {
        output[thisE[0]] = [];
      }
      output[thisE[0]].push(thisE[1]);
    });
    return output;
  }
  return {};
});

// Convert storyblok filter format to algolia format
const adminFiltersStoryBlok = computed(()=> {
  const output = [] as string[][];
  if (props.blok.adminFilters[selectedAdminFilter.value]?.adminFilter?.filters?.filters) {
    Object.keys(props.blok.adminFilters[selectedAdminFilter.value].adminFilter.filters.filters).forEach((i) => {
      const content = props.blok.adminFilters[selectedAdminFilter.value].adminFilter.filters.filters[i];
      if (content.length) {
        output.push(content.map((c)=> {
          return i + ':' + c;
        }));
      }
    });
  }
  // ads the page filters from url, overwrites if exists to fix subcategories
  autoPagePath.value.forEach((f) => {
    const fCat = f.split(':');
    const exist = output.findIndex((fi) => {
      const fiCat = fi[0].split(':');
      return fiCat[0] === fCat[0];
    });
    if (exist > -1) {
      output[exist] = [ f ];
    } else {
      output.push([f]);
    }
  });
  if (isPointShop.value) {
    output.push(['pointShop:true']);
  }
  return output;
});

type AdminFiltersUsed = {
  filterKey: string,
  content: string[]
}[]

/**
 * @todo - perhaps it would be cleaner if this is generated from adminFiltersStoryBlok instead of props
 */
const usedAdminFilter = computed<AdminFiltersUsed>(() => {
  if (props.blok.adminFilters.length) {
    /*return Object.keys(props.blok.adminFilters[0].adminFilter.filters.filters).filter((i)=> {
      const content = props.blok.adminFilters[0].adminFilter.filters.filters[i];
      return (content.length > 0);
    });*/
    let output = Object.keys(props.blok.adminFilters[0].adminFilter?.filters?.filters ?? {}).map((i)=> {
      const content = props.blok.adminFilters[0].adminFilter.filters.filters[i];
      return {
        filterKey: i,
        content,
      };
    }).filter((f) => f.content.length) as AdminFiltersUsed;

    // ads the page filters from url, overwrites if exists to fix subcategories
    Object.keys(autoPageFilters.value).forEach((af) => {
      const exists = output.findIndex((oi) => oi.filterKey === af);
      if (exists > -1) {
        output[exists].content = autoPageFilters.value[af];
      } else {
        output.push({
          filterKey: af,
          content: autoPageFilters.value[af],
        });
      }
    });

    return output;
  }
  return [];
});

const combinedFilters = computed(()=> {
  let thisFacets = [] as string[][];
  Object.keys(userFilters.value).forEach((uFk) => {
    if (userFilters.value[uFk]?.length) {
      const thisFacet = [] as string[];
      userFilters.value[uFk].forEach((fV: string) => {
        thisFacet.push(`${uFk}:${fV}`);
      });
      thisFacets.push(thisFacet);
    }
  });
  thisFacets.push(...adminFiltersStoryBlok.value);
  return thisFacets;
});

// reference https://www.algolia.com/doc/api-reference/search-api-parameters/
// and or syntax: https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
const query = {
  // This controls preselected facets
  facetFilters: adminFiltersStoryBlok.value,
  // This gives us the indicies for the selected filters
  facets: shownFacets,
  hitsPerPage: hitsPerPage.value,
  page: page.value,
  facetingAfterDistinct: true,
} as AlgoliaSearchPayloadRequestOptions;

/**
 * To create the list view we need to get all the variants
 */
if (viewMode.value === 'list') {
  // @ts-ignore
  query.distinct = 8;
}

if (props.searchQuery && props.searchQuery.trim() !== '') {
  query.query = props.searchQuery.trim();
}
if (isPointShop.value) {
  query.numericFilters = [ 'variantStockStatuses.inStock>0' ];
}

const initalAlgoliaResponse = ref<AlgoliaSearchResult | null>(null);

const isManuallySelectedProducts = computed(()=> {
  return manuallySelectedPartNos.value.length > 0;
});

const manuallySelectedUrls = computed(()=> {
  if (props.blok.adminFilters[selectedAdminFilter.value]) {
    return props.blok.adminFilters[selectedAdminFilter.value].manual
      .filter((f) => f.product !== '' && f.product.product !== null)
      .map((m) => m.product.product.url);
  }
  return [];
});

const manuallySelectedPartNos = computed(()=> {
  if (props.blok.adminFilters[selectedAdminFilter.value]) {
    return props.blok.adminFilters[selectedAdminFilter.value].manual
      .filter((f) => f.product !== '' && f.product.product !== null)
      .map((m) => m.product.product.partNo);
  }
  return [];
});

/**
 * Query to algolia, combining adminfilters and userfilters
 */
const algoliaQuery = computed(()=> {
  const query = {
    facetFilters: combinedFilters.value,
    facets: shownFacets,
    hitsPerPage: hitsPerPage.value,
    page: page.value,
    facetingAfterDistinct: true,
    sumOrFiltersScores: false,
  } as AlgoliaSearchPayloadRequestOptions;
  if (viewMode.value === 'list') {
    //@ts-ignore
    query.distinct = 8;
  }
  if (props.searchQuery && props.searchQuery.trim() !== '') {
    query.query = props.searchQuery.trim();
  }
  if (isPointShop.value) {
    query.numericFilters = [ 'variantStockStatuses.inStock>0' ];
  }
  return query;
});

const hasUrlUserFilters = typeof route.query?.userFilters !== 'undefined'
  && !isManuallySelectedProducts.value
  && props.blok.enableFilter;
if (hasUrlUserFilters) {
  userFilters.value = JSON.parse(route.query.userFilters as string);
}

const { data } = await useAsyncData('ssr-search-results' + props.blok._uid,
  async() => {
    const loadSitemap = globalContent.loadSitemap();

    if (isManuallySelectedProducts.value) {
      const loadManual = getProductsByPartNo(manuallySelectedPartNos.value);
      return await Promise.all([loadManual, loadSitemap]);
    } else if (hasUrlUserFilters) {
      // We need to load with and without userfilters, to get all filter facets
      const loadFilter = getFromAlgolia(query, indexName.value);
      const loadUserFilter = getFromAlgolia(algoliaQuery.value, indexName.value);

      return await Promise.all([loadFilter, loadUserFilter, loadSitemap]);
    } else {
      const loadFilter = getFromAlgolia(query, indexName.value);
      return await Promise.all([loadFilter, loadSitemap]);
    }
  }
);

const queryID = ref('');

if (data && data.value && data.value[0]) {
  //@ts-ignore
  initalAlgoliaResponse.value = data.value[0];
  queryID.value = initalAlgoliaResponse?.value?.queryID || '';
} else {
  console.log(data);
  console.error('Problem loading algolia');
  uiStore.setTemporaryError('Problem loading algolia', 0);
}
if (initalAlgoliaResponse.value && isManuallySelectedProducts.value) {
  products.value = convertRawToProducts(initalAlgoliaResponse.value.hits).map((m) => m.product);
  totalProducts.value = products.value.length;
  emit('totalProductsInital', totalProducts.value);
} else if (initalAlgoliaResponse.value) {
  if (viewMode.value === 'list') {
    products.value = convertRawToProducts(initalAlgoliaResponse.value.hits).map((m) => m.product);
  } else {
    products.value = initalAlgoliaResponse.value.hits.map(p => Product.create([ p ], true));
  }
  totalProducts.value = initalAlgoliaResponse.value.nbHits;
  emit('totalProductsInital', totalProducts.value);

  if (initalAlgoliaResponse.value.facets && initalAlgoliaResponse.value.renderingContent && typeof initalAlgoliaResponse.value.facets !== 'undefined') {
    facetsCurrent.value = JSON.parse(JSON.stringify(initalAlgoliaResponse.value.facets));
    facetsInitial.value = JSON.parse(JSON.stringify(initalAlgoliaResponse.value.facets));
  }
  initialLoaded.value = true;
} else {
  console.error('No response from algolia');
}

if (hasUrlUserFilters) {
  products.value = data.value[1].hits.map(p => Product.create([ p ], true));
  if (typeof data.value[1].facets !== 'undefined') {
    facetsCurrent.value = JSON.parse(JSON.stringify(data.value[1].facets));
  }
  totalProducts.value = data.value[1].nbHits;
  emit('totalProductsInital', totalProducts.value);
}

const fastLinksAvailable = computed<boolean>(()=>{
  if (props.blok.subPagesManual && props.blok.subPagesManual.length) {
    return props.blok.subPagesManual.some((s)=> $sbLinkCheck(s.link));
  }
  if (autoGenerateNav.value.length) {
    if (props.blok.subPages === 'subpages' || props.blok.subPages === 'subpages-folder' || props.blok.subPages === 'subpages-pages') {
      return true;
    }

    if (props.blok.subPages === 'brand-subcategories') {
      if (!props.blok.enableFilter || showTabs.value) {
        return false;
      }
      const cF = usedAdminFilter.value.find((f)=> f.filterKey === 'categoryFilters');
      if (!cF || cF.content.length !== 1) {
        return false;
      }
      return true;
    }

  }
  return false;
});

type AutoNav = {
  filterName?: string;
  code: string;
  name: string;
  path?: string;
  isFolder?: boolean;
  selected?: boolean;
}[]

const selectedOption = ref('default');

const autoGenerateNav = computed<AutoNav>(() => {
  // manual selection
  if (props.blok.subPagesManual && props.blok.subPagesManual.length) {
    return props.blok.subPagesManual.map((m)=> {
      return {
        code: m._uid,
        name: m.title,
        path: $sbLinkFix(m.link),
      };
    });
  }

  // selection from storyblok pages
  const depth = autoPagePath.value.length;
  if (props.blok.subPages === 'subpages' || props.blok.subPages === 'subpages-folder' || props.blok.subPages === 'subpages-pages') {
    const selfId = globalContent.currentStory.id;
    const parentId = globalContent.getSitemapItemById(selfId);
    if (parentId) {
      const siblings = globalContent.getSitemapItemByParentId(parentId.parent_id).filter((f)=> f.id !== selfId);
      const allPages = siblings.map((m) => {
        return {
          code: m.id,
          name: m.name,
          path: m.full_slug,
          isFolder: m.is_folder,
        };
      });
      if (props.blok.subPages === 'subpages-folder') {
        return allPages.filter((f)=> f.isFolder);
      }
      if (props.blok.subPages === 'subpages-pages') {
        return allPages.filter((f)=> !f.isFolder);
      }
      return allPages;
    }
  }

  // brands - then subcategories
  if (props.blok.subPages === 'brand-subcategories') {
    if (depth === 0) {
      if (facetsInitial.value['brandFilters']) {
        return Object.keys(facetsInitial.value['brandFilters']).map((bf) => {
          return {
            code: bf,
            name: globalContent.getFilterOption('brandFilters.' + bf).name,
            path: `${route.path}/filterpage/brandFilters:${bf}`.replace('//','/'),
          };
        });
      }
    }
    /**
     * These buttons are not links, but instead controls the userFilters
     */
    if (depth === 1) {
      if (facetsInitial.value['brandFilters']) {
        return Object.keys(facetsInitial.value['categoryFilters']).map((bf) => {
          const thisFilterOption = globalContent.getFilterOption('categoryFilters.' + bf);
          let selected = false;
          const categoryFilters = userFilters.value.categoryFilters ? userFilters.value.categoryFilters : [];
          if (Object.keys(userFilters.value).length === 0 && thisFilterOption.parentCode === null) {
            selected = true;
          }
          if (categoryFilters.includes(bf)) {
            selected = true;
          }
          return {
            filterName: 'categoryFilters',
            code: bf,
            name: thisFilterOption.parentCode !== null ? thisFilterOption.name : $t('productList.autoNav.all'),
            selected,
          };
        });
      }
    }
  }
  return [] as AutoNav;
});

const autoGeneratedHeadline = computed(()=> {
  const depth = autoPagePath.value.length;
  if (props.blok.subPages === 'brand-subcategories') {
    if (depth === 1) {
      return props.blok.adminFilters[0].name + ' '
          + globalContent.getFilterOption(autoPagePath.value[0].replace(':', '.')).name;
    }
    if (depth === 2) {
      return globalContent.getFilterOption(autoPagePath.value[0].replace(':', '.')).name
      + ' '
      + globalContent.getFilterOption(autoPagePath.value[1].replace(':', '.')).name;
    }
  }
  return null;
});

const headline = computed(()=> {
  let points = cartStore.pointsAvailable +'';
  if (points === '-1') {
    points = '';
  }
  return props.blok.headline.replace('{points}', points);
});

const setFilter = (input: UserFilters, scroll = true) => {
  Object.keys(input).forEach((f) => {
    if (!input[f].length) {
      delete input[f];
    }
  });
  userFilters.value = input;
  page.value = 0;
  loadResults(false, scroll);
};

const swiperIndex = ref(0);

const loadResults = async(append = false, scroll = true) => {
  loading.value = true;
  if (append) {
    page.value++;
  }

  // * * * * * * * * Load manual products
  if (isManuallySelectedProducts.value) {
    const manualResult = await getProductsByPartNo(manuallySelectedPartNos.value);
    if (manualResult) {
      products.value = convertRawToProducts(manualResult.hits).map(p => p.product);
      totalProducts.value = products.value.length;
      if (scroll) {
        scrollToProducts();
      }
    } else {
      console.error('Create an error handler');
    }

  } else {
    // * * * * * * * * Load filtered products
    const results = await getFromAlgolia<ProductRaw[]>(algoliaQuery.value, indexName.value);
    if (results) {
      queryID.value = results.queryID || '';
      let loadedProducts = [] as ProductModel[];
      if (viewMode.value === 'list') {
        loadedProducts = convertRawToProducts(results.hits).map(p => p.product);
      } else {
        loadedProducts = results.hits.map(p => Product.create([ p ], true));
      }
      if (append) {
        products.value = products.value.concat(loadedProducts);
      } else {
        products.value = loadedProducts;
        if (scroll) {
          scrollToProducts();
        }
      }
      if (typeof results.facets !== 'undefined') {
        facetsCurrent.value = JSON.parse(JSON.stringify(results.facets));
      }
      totalProducts.value = results.nbHits;
    } else {
      console.error('No algolia response');
    }
  }
  loading.value = false;
  nextTick(()=> {
    swiperIndex.value++;
    // trigger for algolia:
    pushToDataLayer({ event: 'Hits Viewed' });
  });
};

const scrollToProducts = () => {
  if (topOfProductList.value && !route.query?._storyblok) {
    setTimeout(()=> {
      if (topOfProductList.value) {
        const top = topOfProductList.value.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top - 80;
        window.scrollTo({
          behavior: 'smooth',
          top,
        });
      }
    }, 300);
  }
};

const sortOptions = computed(()=> {
  return globalContent.filterSettings.sortIndices.map((i) => {
    return {
      value: i.code,
      label: $t('filter.sort.'+i.code),
    };
  });
});
const setSort = () => {
  const index = globalContent.filterSettings.sortIndices.find((f) => f.code === selectedOption.value);
  if (index) {
    indexName.value = index.key;
    page.value = 0;
    loadResults(false, false);
  }
};

const toggleAdminFilter = (index: number) => {
  page.value = 0;
  selectedAdminFilter.value = index;
  userFilters.value = {};
  loadResults(false, false);
};

const showStoryblokErrors = computed(()=> {
  if (route.query?._storyblok) {
    let output = [];
    if (props.blok.enableFilter && showTabs.value)  {
      output.push('Filter disabled when using tabs');
    }

    if (props.blok.subPages !== 'off' && showTabs.value) {
      output.push('Auto generate fastlinks not compatible with tabs');
    }

    if ((props.blok.desktopDesign === 'slider') && props.blok.enableFilter) {
      output.push('Desktop design slider not compatible with filter');
    }

    if (props.blok.favoriteBrandButton && !props.isBrandPage) {
      output.push('Favorite brand button only available on BrandPages');
    }

    if (props.blok.subPages === 'brand-subcategories') {
      if (!props.blok.enableFilter) {
        output.push('Auto generate fastllinks "brand-subcategories" needs filter to be enabled');
      }
      const cF = usedAdminFilter.value.find((f)=> f.filterKey === 'categoryFilters');
      if (!cF || cF.content.length !== 1) {
        output.push('Auto generate fastllinks "brand-subcategories" must have a single categoryFilter as selection.');
      }
    }

    return output;
  }
  return [];
});

if (route.query?._storyblok) {
  watch(() => props.blok.adminFilters, () => {
    loadResults(false, false);
  });
  watch(() => props.blok.limit, () => {
    loadResults(false, false);
  });
  watch(() => props.blok.limitDesktop, () => {
    loadResults(false, false);
  });
}

watch(totalProducts, (newVal) => {
  emit('totalProducts', newVal);
});

watch(
  ()=> userFilters.value,
  (newVal: UserFilters) => {
    if (Object.keys(newVal).length === 0) {
      history.replaceState(
        {},
        '',
        route.path
      );
    } else {
      history.replaceState(
        {},
        '',
        route.path + '?userFilters=' + encodeURI(JSON.stringify(newVal))
      );
    }
  },
  { deep: true }
);

/**
 * For some reason, when using browser back button, the ?userFilters is gone,
 * but the filter is still active.
 * This just changes the url to match what is actually showing
 */
onActivated(()=> {
  if (Object.keys(userFilters.value).length > 0) {
    history.replaceState(
      {},
      '',
      route.path + '?userFilters=' + encodeURI(JSON.stringify(userFilters.value))
    );
  }
});

const jumpToProducts = () => {
  if (topOfProductList.value) {
    const top = topOfProductList.value.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top - 100;
    window.scrollTo({
      behavior: 'smooth',
      top,
    });
  }
};

// Makes it possible to have filter buttons outside filterpanel
// This seems a bit circluar, but it's filterPanel that takes care of userfilter
const filterPanel = ref<typeof FilterPanel | null>(null);
const sendToFilterPanel = (index: number) => {
  if (filterPanel.value) {
    const filterKey = autoGenerateNav.value[0].filterName;
    const values = autoGenerateNav.value.map((v, vIndex) => {
      return {
        value: v.code,
        selected: vIndex === index,
      };
    });
    filterPanel.value.setSelected(filterKey, values, false);
  } else {
    console.warn('Filter panel not preset');
  }
};

const setView = async(mode: 'list' | 'grid') => {
  const prev = viewMode.value;
  viewModeCookie.value = mode;
  if (prev === 'grid' && mode === 'list') {
    // Since list mode loads more data - we force a reload
    await loadResults();
  }
};

const productCardDesign = computed(()=> {
  if (props.blok.enableFilter && viewMode.value === 'list') {
    return 'list';
  }
  if (
    (uiStore.isMobile && props.blok.mobileDesign === 'featured') ||
    (!uiStore.isMobile && props.blok.desktopDesign === 'featured') ||
      (!uiStore.isMobile && props.blok.desktopDesign === 'slider-featured')
  ) {
    return 'featured';
  }
  return 'standard';
});

const mobileDesignOut = computed(()=> {
  if (viewMode.value === 'list') {
    return 'list';
  }
  return 'grid grid-cols-2 tabletPortraitOnly:grid-cols-3 gap-x-2 desk:gap-x-12';
});

const desktopDesign = computed(()=> {
  if (viewMode.value === 'list') {
    return 'list';
  }

  if (props.blok.desktopDesign === 'featured') {
    return 'desk:grid-cols-2 desk:gap-y-12';
  }

  if (props.blok.desktopDesign === 'large') {
    if (props.blok.enableFilter) {
      return 'desk:grid-cols-3';
    }
    return 'desk:grid-cols-4';
  }

  if (props.blok.desktopDesign === 'slider' && !props.blok.enableFilter) {
    return 'slider';
  }
  if (props.blok.desktopDesign === 'slider-large' && !props.blok.enableFilter) {
    return 'slider-large';
  }
  if (props.blok.desktopDesign === 'slider-featured' && !props.blok.enableFilter) {
    return 'slider-featured';
  }

  // Default design (and fallback)
  if (props.blok.enableFilter && !showTabs.value) {
    return 'desk:grid-cols-4';
  }
  return 'desk:grid-cols-5';
});

const showSlider = computed(()=> {
  if (uiStore.isMobile) {
    if (props.blok.mobileDesign === 'slider' || props.blok.mobileDesign === 'featured') {
      return true;
    }
  } else {
    if (desktopDesign.value === 'slider' || desktopDesign.value === 'slider-large'
        || desktopDesign.value === 'slider-featured') {
      return true;
    }
  }
  return false;
});

const showSliderButtons = computed(()=> {
  let limit = 0;
  switch (desktopDesign.value) {
    case 'slider':
      limit = 5;
      break;
    case 'slider-large':
      limit = 4;
      break;
    case 'slider-featured':
      limit = 2;
      break;
  }

  if (isTabsInline.value) { limit--; }
  if (isTabWithImage.value) { limit--; }
  return products.value.length > limit;
});

const slidesPerView = computed(()=> {
  if (uiStore.isMobile) {
    if (props.blok.mobileDesign === 'featured') {
      return 1.1;
    }
    return 2.1;
  }
  if (desktopDesign.value === 'slider-large') {
    return 4;
  }
  if (desktopDesign.value === 'slider-featured') {
    return 2;
  }
  return 5;
});

const desktopSliderBtnStyle = computed(()=> {
  let top = 174;
  if (desktopDesign.value === 'slider-large') {
    top = 215;
  }
  if (desktopDesign.value === 'slider-featured') {
    top = 218;
  }
  return {
    top: `${top}px !important`,
  };
});

const isTabsInline = computed(()=> {
  return (!uiStore.isMobile && props.blok.tabsInline);
});

const isTabWithImage = computed(()=> {
  if (!props.blok.adminFilters || !props.blok.adminFilters[selectedAdminFilter.value]) {
    return false;
  }
  return (props.blok.adminFilters[selectedAdminFilter.value].image && props.blok.adminFilters[selectedAdminFilter.value].image.filename?.trim() !== '');
});

watch(
  selectedOption,
  () => {
    setSort();
  }
);
const productCardOrder = (index: number) => {
  if (!isTabsInline.value && !isTabWithImage.value) {
    return {};
  }
  if (isTabsInline.value) {
    index++;
  }
  if (isTabWithImage) {
    index++;
  }

  return {
    order: index,
  };
};

// Sizing things in sliders
const swiperH = ref(-1);
const updateSwiperH = (h: number) => {
  if (h < 500) {
    swiperH.value = h;
  }
};
const swiperHstyle = computed(()=> {
  if (swiperH.value > -1) {
    return {
      height: `${swiperH.value}px`,
    };
  }
  return {};
});

const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'ItemList',
  numberOfItems: products.value.length,
  itemListElement: products.value.map(productItem => {
    const activeVariant = productItem?.variants[0];
    return {
      '@type': 'Product',
      url: productItem.url,
      name: productItem.name,
      image: productItem.imageList,
      description: productItem.metaDescription,
      sku: productItem.partNo,
      mpn: productItem.partNo,
      brand: {
        '@type': 'Brand',
        name: productItem.brand.name,
      },
      offers: {
        '@type': 'Offer',
        url: productItem.url,
        priceCurrency: getLocalCurrency(),
        // price: $toNumber(productItem.priceInfo.price),
        itemCondition: 'https://schema.org/NewCondition',
        availability: activeVariant?.canAddToCart ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      },
    };
  }),
} as any;

useJsonld(jsonLd);

onMounted(()=> {
  // trigger for algolia:
  pushToDataLayer({ event: 'Hits Viewed' });

  localStorage.setItem('queryID', queryID.value);
});

watch(queryID, ()=> {
  localStorage.setItem('queryID', queryID.value);
});

</script>

<style scoped lang="postcss">
.tabImageHeight {
  height: calc(100% - 2px);
  @screen desk {
    height: calc(100% - 12px);
  }
}
.view.active--grid {
  @apply opacity-100;
}
.view.active--list {
  @apply opacity-100;
}
</style>
